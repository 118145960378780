import React, { useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import Navigation from "./components/Navigation";
import HomePage from "./pages/HomePage";
import Gallery from "./pages/Gallery";
import About from "./pages/About";
import Participation from "./pages/Participation";
import { HashRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  const [currentPage, setCurrentPage] = useState("home");

  const renderPage = () => {
    switch (currentPage) {
      case "home":
        return <HomePage />;
      case "gallery":
        return <Gallery />;
      case "participation":
        return <Participation />;
      case "about":
        return <About />;
      default:
        return <HomePage />;
    }
  };

  return (
    <div className="App">
      <Navigation setCurrentPage={setCurrentPage} />
      <div className="content">{renderPage()}</div>
    </div>
  );
}

export default App;
