import React, { useState } from "react";
import "../styles/main.css";
import videoHeader from "../assets/Website-Header.mp4";
import Navigation from "../components/Navigation";

const HomePage = () => {
  return (
    <div className="video-header">
      <video className="header-video" src={videoHeader} autoPlay loop muted></video>
      <div className="overlay-content">
        <h1>Löpsival 2025</h1>
        <h3>01. - 03. August 2025</h3>
      </div>
    </div>
  );
};

export default HomePage;
