import React, { useState } from "react";
import "../styles/gallery.css";
import Navigation from "../components/Navigation";

const importAll = (r) => r.keys().map(r);

const images = importAll(require.context("../assets/gallery", false, /\.(png|jpe?g|svg)$/));
const video = require("../assets/gallery/Loepsival Aftermovie 2024.mp4"); // Replace with your video path

const Gallery = () => {
  const [fullscreenImage, setFullscreenImage] = useState(null);

  const openFullscreen = (src) => {
    setFullscreenImage(src);
  };

  const closeFullscreen = () => {
    setFullscreenImage(null);
  };

  return (
    <div>
      <div className="gallery-container">
        <div className="gallery-video-container">
          <video controls className="gallery-video">
            <source src={video} type="video/mp4" />
          </video>
        </div>
        {images.map((media, index) => (
          <div key={index} className="gallery-item" onClick={() => openFullscreen(media)}>
            <img src={media} alt={`Gallery item ${index + 1}`} className="gallery-image" />
          </div>
        ))}
      </div>

      {fullscreenImage && (
        <div className="fullscreen-overlay" onClick={closeFullscreen}>
          <img src={fullscreenImage} alt="Fullscreen view" className="fullscreen-image" />
        </div>
      )}
    </div>
  );
};

export default Gallery;
