import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../styles/navigation.css";

const Navigation = ({ setCurrentPage }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const navigate = (page) => {
    setCurrentPage(page);
    setMenuOpen(false);
  };

  return (
    <nav className="navbar">
      <div className="menu-icon" onClick={toggleMenu}>
        <div className={isMenuOpen ? "bar open" : "bar"}></div>
        <div className={isMenuOpen ? "bar open" : "bar"}></div>
        <div className={isMenuOpen ? "bar open" : "bar"}></div>
      </div>
      <ul className={isMenuOpen ? "nav-links open" : "nav-links"}>
        <li>
          <a onClick={() => navigate("home")}>Home</a>
        </li>
        <li>
          <a onClick={() => navigate("gallery")}>Gallery</a>
        </li>
        <li>
          <a onClick={() => navigate("participation")}>Participation</a>
        </li>
        <li>
          <a onClick={() => navigate("about")}>About</a>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
